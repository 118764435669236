import React from "react"
import Layout from "../components/layout"
import Button from "../components/button"
import { InputWithLabel } from "../components/form"
import { Card } from "../style/containers"
import { maintenanceCode } from "../constants"

export default function Maintenance ({ unlock }) {
    const [ error, setError ] = React.useState('')
    const [ maintCode, setMaintCode ] = React.useState('')

    const changeMaintCode = (event) => {
        setMaintCode(event.target.value)
    }

    const submit = () => {
        if (maintenanceCode == maintCode) {
            unlock(true)
        } else {
            setError('Incorrect Code')
        }
    }

    return (
        <Layout emailNoCheck={true}>
            <Card>
                <div style={{ display: 'flex', flexDirection: 'column', margin: '10px auto'}}>
                    <InputWithLabel
                        type='text'
                        label='Enter maintenance code to unlock'
                        name='maintenance-code'
                        onChange={changeMaintCode}
                        errorText={error}
                    />

                    <Button
                        type='submit'
                        onClick={submit}
                    >
                        Submit
                    </Button>
                </div>
            </Card>
        </Layout>
    )
}